.background-ease() {
  transition: background-color 0.3s ease, color 0.3s ease;
}
.table-div {
  display: table;
  width: 100%;
  > div {
    display: table-row;
    > * {
      display: table-cell;
      &.middle {
        vertical-align: middle;
      }
    }
  }
}
//.sticky(@top) {
//  // https://developer.mozilla.org/fr/docs/Web/CSS/position#Positionnement_adh%C3%A9rent
//  position: -webkit-sticky;
//  position: sticky;
//  top: @top;
//}
.flex-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.btn-header {
  .button-variant(@btn-header-color; @btn-header-bg; @btn-header-border);
}
