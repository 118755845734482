body {
  background-color: #fff;
}

// Adaptation de la navbar
.navbar-inverse.navbar-saur {
  &:before {
    background-color: white;
  }
  .container {
    .navbar-header .navbar-brand img {
      width: 100%;
      max-width: 420px;
    }
    .main-navig {
      position: inherit;
      > li {
        &.water {
          a:before {
            content: "";
          }
        }
        &.customer {
          a:before {
            content: "";
          }
        }
      }
      #wrapper-primary {
        left: 0;
        width: 100%;
        li a::before {
          content: '';
        }
      }
    }
  }
}

.navbar-brand {
  height: 100%;
}

//Couleur des tuiles page d'accueil
.home-row1 {
  .tile {
    h2::after {
      background-color: #fff;
    }
    a {
      color: #fff;
    }
    &:nth-of-type(1) {
      a {
        background-color: @brand-primary;
      }
    }
    &:nth-of-type(2) {
      a {
        background-color:@water-section;
      }
    }
    &:nth-of-type(3) {
      a {
        background-color: @customer-section;
      }
    }
    &:nth-of-type(4) {
      a {
        background-color: @brand-primary;
      }
    }
  }

  div.tile-bottom svg {

    fill: #fff;

  }
}
