media {
  display: block;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.typography {
  media {
    margin: @padding-large-vertical @padding-large-horizontal;
    margin-top: 0;
  }
}
.pull-left media {
  margin-left: 0;
}
.pull-right media {
  margin-right: 0;
}
iframe {
  border: 0;
}

// For video, see
//   https://stackoverflow.com/a/6615994
.iframe-sizer {
  display: inline-block;
  position: relative;
  width: 100%;
  iframe {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100%;
  }
  .show-stopper {
    margin-top: 75%; // 4/3 ratio
  }
}

.modal-backdrop {
  background-color: black;
  opacity: 0.8;
}
.media-modal {
  .modal-dialog {
    width: @modal-lg;
  }
  .modal-content {
    background: white;
  }
}

// Responsive size
@media (max-width: @screen-md-max) {
  .media-modal .modal-dialog {
    width: @modal-md;
  }
}
@media (max-width: @screen-sm-max) {
  .media-modal .modal-dialog {
    width: @modal-sm;
  }
}
@media (max-width: @screen-xs-max) {
  .media-modal .modal-dialog {
    width: 100%;
  }
}
