.typography {
  p, li {
    text-align: justify;
  }
  ul {
    margin-bottom: 0;
    li {
      //list-style: disc;
      margin: 0 0 (@line-height-computed / 4);
      li:first-child {
        margin-top: (@line-height-computed / 4);
      }
    }
  }
  h4 {
    margin-top: @line-height-computed;
  }
  h5 {
    margin-top: ceil(@line-height-computed * 0.75);
  }
}
