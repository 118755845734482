//
// Styles spécifiques Saur - Override + thème
// --------------------------------------------------

// Zone d'override

h1, .h1 {
  font-size: @font-size-h1-xs;
  @media (min-width: @screen-sm-min) {
    font-size: @font-size-h1-sm;
  }
  @media (min-width: @screen-md-min) {
    font-size: @font-size-h1;
  }
}

h2, .h2 {
  font-size: @font-size-h2-xs;
  @media (min-width: @screen-sm-min) {
    font-size: @font-size-h2;
  }
}

h3, .h3 {
  font-size: @font-size-h3-xs;
  @media (min-width: @screen-sm-min) {
    font-size: @font-size-h3;
  }
}

/*
h4, .h4 { font-size: @font-size-h4; }
h5, .h5 { font-size: @font-size-h5; }
h6, .h6 { font-size: @font-size-h6; }
*/

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: (@line-height-computed*1.5);
  margin-bottom: @line-height-computed;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
}

a {
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
}

//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  position: relative;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 2px solid transparent;
  /*white-space: nowrap;*/
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-small; @line-height-base; @border-radius-btn-rounded);
  .user-select(none);
  transition: all ease-in-out 0.3s;

  //  &,
  //  &:active,
  //  &.active {
  //    &:focus,
  //    &.focus {
  //      .tab-focus();
  //    }
  //  }

  //&:hover,
  //&:focus,
  //&.focus {
  //  color: @brand-primary;
  //  //    text-decoration: none;
  //}

  //  &:active,
  //  &.active {
  //    outline: 0;
  //    background-image: none;
  //    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  //  }

  //  &.disabled,
  //  &[disabled],
  //  fieldset[disabled] & {
  //    cursor: @cursor-disabled;
  //    .opacity(.65);
  //    .box-shadow(none);
  //  }

  //  a& {
  //    &.disabled,
  //    fieldset[disabled] & {
  //      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  //    }
  //  }
}

// Alternate buttons
// --------------------------------------------------
.btn-arrow {
  &:after {
    display: inline-block;
    font-family: @font-family-icon;
    margin-left: 5px;
    content: "\f178";
    @media (min-width: @screen-sm-min) {
      margin-left: 20px;
    }
    @media (min-width: @screen-md-min) {
      margin-left: 35px;
    }
    @media (min-width: @screen-lg-min) {
      margin-left: 50px;
    }
  }
}

.btn-primary-dark {
  color: @brand-primary-dark;
}

.btn-water {
  color: @water-section;
}

.btn-customer {
  color: @customer-section;
}

.btn-help {
  color: @help-section;
}

.btn-default {
  .button-variant(@btn-default-color; @btn-default-color-over; @btn-default-bg; @btn-default-bg-over; @btn-default-border);
}

.btn-white {
  .button-variant(@btn-white-color; @btn-white-color-over; @btn-default-bg; @btn-white-bg-over; @btn-white-border);
  &.btn-primary-dark {
    &:focus,
    &:hover {
      color: @brand-primary-dark;
    }
  }
  &.btn-water {
    &:focus,
    &:hover {
      color: @water-section;
    }
  }
  &.btn-customer {
    &:focus,
    &:hover {
      color: @customer-section;
    }
  }
  &.btn-help {
    &:focus,
    &:hover {
      color: @help-section;
    }
  }
}

.btn-filled-default {
  .button-filled-variant(@brand-primary; @btn-default-color-over; @brand-primary; @btn-default-border);
}

.btn-filled-white {
  .button-filled-variant(@btn-white-bg-over; @brand-primary; @btn-white-color; @btn-white-border);
  &.btn-primary-dark {
    color: @brand-primary-dark;
    &:focus,
    &:hover {
      color: @btn-white-color;
    }
  }
  &.btn-water {
    color: @water-section;
    &:focus,
    &:hover {
      color: @btn-white-color;
    }
  }
  &.btn-customer {
    color: @customer-section;
    &:focus,
    &:hover {
      color: @btn-white-color !important;
    }
  }
  &.btn-help {
    color: @help-section;
    &:focus,
    &:hover {
      color: @btn-white-color;
    }
  }
}

/*.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}
// Success appears as green
.btn-success {
  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}


*/

// Input formulaire
// --------------------------------------------------

select,
textarea,
input,
.form-control /*input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"]*/
{
  margin-bottom: @form-group-margin-bottom;
  border: 2px solid @brand-primary;
  height: 40px;
  border-radius: @border-radius-btn-rounded;
  padding-left: @padding-large-horizontal;
  padding-right: @padding-large-horizontal;
  background-color: transparent;
  color: @brand-primary;
  &::-webkit-input-placeholder {
    color: @gray-light;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: @gray-light;
    opacity: 1;
  }
  /* firefox 19+ */
  &:-ms-input-placeholder {
    // IE11 bug: si une règle CSS plus spécifique cible l'attribut "color"
    // du input (même si ça ne cible pas le placeholder) IE11 surcharge le
    // placeholder avec l'autre règle.
    color: @gray-light !important;
    opacity: 1;
  }
  /* ie */
  &:-moz-placeholder {
    color: @gray-light;
    opacity: 1;
  }
}

.input-white {
  border: 2px solid #fff;
  color: #fff;
  &::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
  /* firefox 19+ */
  &:-ms-input-placeholder {
    color: #fff;
    opacity: 1;
  }
  /* ie */
  &:-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
}

.input-filled-white {
  color: @brand-primary;
  background-color: #fff;
  &::-webkit-input-placeholder {
    color: @gray-light;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: @gray-light;
    opacity: 1;
  }
  /* firefox 19+ */
  &:-ms-input-placeholder {
    color: @gray-light;
    opacity: 1;
  }
  /* ie */
  &:-moz-placeholder {
    color: @gray-light;
    opacity: 1;
  }
}

.input-100 {
  width: 100%
}

.content-inter {
  .inter {
    select,
    textarea,
    input {
      border: 1px solid @gray-lighter;
      border-radius: 0;
    }
    button[type="submit"] {
      display: block;
    }
  }

  > .alert {
    margin: @padding-large-vertical @padding-large-horizontal @padding-large-vertical*2;
  }

  &.search, .search {
    background-color: transparent !important;
  }
  .search {
    max-width: 390px;
    input {
      border-radius: 20px 0 0 20px;
      padding-left: 1em;
    }
    .btn {
      line-height: 1;
    }
  }
  &.search .search-result {
    > div + div {
      margin-top: @line-height-computed * 1.5;
      padding-top: @line-height-computed * 1.5;
      border-top: 1px solid @gray-lighter;
    }
    h2 {
      margin-bottom: @line-height-computed * 0.5;
    }
  }
}

.navbar {
  margin-bottom: 0;
  border: none;
}

// Brand/project name
.navbar-brand {
  padding: 0;
}

// Styles spécifiques
body {
  -webkit-font-smoothing: antialiased;
}

/*NAV PRINCIPALE*/
.navbar-inverse.navbar-saur {
  background-color: @customer-section;
  z-index: 150;
  @media (min-width: @screen-sm-min) {
    background-color: transparent;
  }
  .container {
    padding: 0;
    @media (min-width: @screen-sm-min) {
      padding: 0 15px;
    }
    .navbar-header {
      @media (max-width: @screen-xs-max) {
        margin-left: 10px;
        margin-right: 0;
      }
      .navbar-brand {
        position: absolute;
        top: 66px;
        @media (min-width: @screen-sm-min) {
          position: relative;
          top: auto;
        }
        img {
          height: auto;
          width: 150px;
          @media (min-width: @screen-sm-min) {
            width: 150px;
          }
          @media (min-width: @screen-md-min) {
            width: 180px;
          }
          @media (min-width: @screen-lg-min) {
            width: 210px;
          }
        }
      }
    }
    .main-navig {
      float: right;
      position: relative;
      padding: 0;
      margin: 0;
      width: 100%;
      @media (min-width: @screen-sm-min) {
        width: auto;
      }
      @media (min-width: @screen-md-min) {
        /* margin-right: 25px; THIS IS A BUG / IF SOMEONE CHANGES THIS ONCE AGAIN appeller Pierre avant pour discuter */
      }
      @media (min-width: @screen-lg-min) {
        /* margin-right: 70px; THIS IS A BUG / IF SOMEONE CHANGES THIS ONCE AGAIN appeller Pierre avant pour discuter */
      }
      > li {
        float: left;
        list-style: none;
        width: 20%;
        @media (min-width: @screen-sm-min) {
          width: auto;
        }
        & > a {
          display: block;
          padding-bottom: 22px;
          padding-top: 22px;
          color: #fff;
          line-height: 20px;
          padding-left: 1px;
          padding-right: 1px;
          height: 66px;
          font-weight: 500;
          font-size: @font-size-large;
          text-align: center;
          position: relative;
          span {
            display: none;
            @media (min-width: @screen-sm-min) {
              display: flex;
            }
            @media (min-width: @screen-lg-min) {
              display: inline-block;
            }
          }
          @media (min-width: @screen-sm-min) {
            font-size: @font-size-x-small;
            padding-top: 12px;
            padding-left: 10px;
            padding-right: 10px;
          }
          @media (min-width: @screen-md-min) {
            padding-left: 20px;
            padding-right: 20px;
            font-size: @font-size-small;
            font-weight: 700;
          }
          @media (min-width: @screen-lg-min) {
            padding-top: 22px;
            padding-left: 32px;
            padding-right: 32px;
            font-size: @font-size-small;
          }
          &:before {
            display: inline-block;
            font-family: @font-family-icon;
            margin-right: 0;
            font-weight: 400;
            @media (min-width: @screen-sm-min) {
              margin-right: 10px;
            }
            @media (min-width: @screen-md-min) {
              margin-right: 12px;
            }
          }
        }
        &.current {
          & > a {
            border-bottom: 2px solid #fff;
            border-left: 1px solid #fff;
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            padding-left: 0; // Supprime 1px de padding pour compenser le border-left
            @media (min-width: @screen-sm-min) {
              padding-left: 9px; // Supprime 1px de padding pour compenser le border-left
            }
            @media (min-width: @screen-md-min) {
              padding-left: 19px; // Supprime 1px de padding pour compenser le border-left
            }
            @media (min-width: @screen-lg-min) {
              padding-left: 31px; // Supprime 1px de padding pour compenser le border-left
            }
            &:after {
              display: block;
              content: "";
              position: absolute;
              left: 50%;
              margin-left: -2px;
              bottom: -6px;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 4px solid #fff;
              height: 0;
              width: 0;
            }
          }
        }
        &.search {
          .btn-nav(@brand-primary-dark);
          span {
            display: none;
          }
          a {
            line-height: 17px;
            padding-top: 22px;
            padding-left: 20px;
            padding-right: 20px;
            &:before {
              content: "\f002";
              margin-right: 0;
              font-size: @font-size-large;
            }
          }
          &.current,
          &.not-current,
          &.not-current:hover {
            a {
              border-left: none;
              border-bottom: 0;
              padding-left: 20px; //restaure le padding original du bouton
              &:after {
                display: none;
              }
            }
          }
        }
        &.home,
        &.primary {
          .btn-nav(@brand-primary);
        }
        &.home {
          a:before {
            content: "\f015";
          }
        }
        &.primary {
          a:before {
            content: "\f0c9";
          }
        }
        &.water {
          .btn-nav(@water-section);
          a:before {
            content: "\f041";
          }
        }
        &.customer {
          .btn-nav(@customer-section);
          a:before {
            content: "\f007";
          }
        }
      }
    }
  }
  &:before {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    left: 50%;
    height: 66px;
    background-color: @customer-section;
  }
}

/*SOUS MENU*/
.wrapp-nav {
  z-index: 168; // Over site image logo

  // Transition (does not work, why...)
  &.opened {
    transition: all 0.5s ease-in;
  }
  &:not(.opened) {
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease-in;
  }

  position: absolute;
  width: 100%;
  box-shadow: 10px 50px 50px 0px rgba(0, 0, 0, 0.25);
  .container {
    position: relative;
    color: #fff;
    padding: 0;
    font-size: @font-size-small;
  }
  .title {
    font-size: @font-size-medium;
    font-weight: 800;
    margin: 0;
    text-transform: uppercase;
    line-height: 1;
    @media (min-width: @screen-sm-min) {
      font-size: @font-size-large;
    }
    @media (min-width: @screen-lg-min) {
      font-size: @font-size-xx-large;
    }
  }
  p {
    margin: 5px 0 10px 0;
    strong {
      font-weight: 500;
    }
  }
  a:not(.btn) {
    color: #fff;
  }
}

#wrapper-search {
  top: 0;
  height: 66px;
  background: @brand-primary-dark;
  pointer-events: none;
  z-index: 200;
  width: auto;
  left: 0;
  right: 0;
  @media (min-width: @screen-sm-min) {
    right: 50%;
    margin-right: -220px;
  }
  @media (min-width: @screen-md-min) {
    margin-right: -260px;
  }
  @media (min-width: @screen-lg-min) {
    margin-right: -300px;
  }
  #search {
    float: right;
    width: 100%;
    @media (min-width: @screen-sm-min) {
      width: 595px;
    }
    @media (min-width: @screen-md-min) {
      width: 745px;
    }
    @media (min-width: @screen-lg-min) {
      width: 885px;
    }
    .title {
      line-height: 66px;
      text-transform: none;
      pointer-events: auto;
      color: #fff;
    }
    form {
      background: @brand-primary-dark;
      height: 66px;
      padding: 0;
      pointer-events: auto;
      padding: 0 0 0 15px;
      @media (min-width: @screen-sm-min) {
        border-left: 1px solid #fff;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
      }
      input {
        width: 65%;
        font-size: @font-size-small;
        border: none;
        background: transparent;
        height: 66px;
        color: #fff;
        @media (min-width: @screen-sm-min) {
          width: 42%;
        }
        @media (min-width: @screen-md-min) {
          width: 50%;
        }
        @media (min-width: @screen-lg-min) {
          width: 55%;
        }
      }
      button {
        margin: 10px 20px 0 0;
        float: right;
        @media (min-width: @screen-sm-min) {
          margin: 0 20px 0 0;
          float: none;
        }
      }
      a {
        display: none;
        @media (min-width: @screen-sm-min) {
          display: inline-block;
          padding: 7px 30px;
          &:before {
            font-family: @font-family-icon;
            content: "\f00d";
            font-size: @font-size-medium;
          }
        }
      }
    }
  }
}

#wrapper-primary {
  background: @brand-primary;
  a {
    outline: none; // avoid dashes around links when clicking
  }
  .container {
    position: static;
    border-top: 1px solid #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    .navbar-nav {
      padding: 0;
      margin: 0;
      width: 100%;
      > li {
        list-style: none;
        position: static;
        border-bottom: 1px dashed #fff;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
        float: none;
        @media (min-width: @screen-md-min) {
          float: left;
          width: 20%;
        }
        > a {
          font-weight: 700;
          padding-top: 15px;
          padding-bottom: 15px;
          text-align: center;
          position: relative;
          z-index: 2;
          font-size: @font-size-medium;
          &:focus,
          &:hover {
            background: transparent;
          }
          @media (min-width: @screen-md-min) {
            font-size: @font-size-small;
            padding-top: 30px;
            padding-bottom: 30px;
          }
        }
        &.hover {
          > a {
            border-bottom: 2px solid #fff;
            &:after {
              display: block;
              content: "";
              position: absolute;
              left: 50%;
              margin-left: -2px;
              bottom: -6px;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 4px solid #fff;
              height: 0;
              width: 0;
            }
          }
        }
        &.not-hover {
          > a {
            opacity: 0.2;
          }
        }
        .wrapp-sous-rub {
          display: none;
          position: relative;
          z-index: 1;
          .wrapp-sous-rub {
            display: block;
          }
          @media (min-width: @screen-md-min) {
            position: absolute;
            background-color: @brand-primary;
            width: 100%;
            left: 0;
            box-shadow: 10px 50px 50px 0 rgba(0, 0, 0, 0.25);
            .wrapp-sous-rub {
              box-shadow: none;
              padding-left: 15px;
              left: auto;
              position: static;
            }
          }
        }
        ul {
          padding: 20px 30px;
          @media (min-width: @screen-md-min) {
            padding: 20px 0;
          }
          li {
            list-style: none;
            font-weight: 500;
            margin-bottom: 10px;
            @media (min-width: @screen-md-min) {
              &:nth-child(5n+5) {
                clear: left;
                margin-left: 25%;
                margin-bottom: 0;
              }
            }
            &.title {
              font-weight: 800;
              margin-bottom: 15px;
            }
            a {
              color: #fff;
            }
            ul {
              position: relative;
              padding: 0;
              @media (min-width: @screen-md-min) {
                display: block;
                padding: 10px 0 30px;
              }
              li {
                font-weight: 400;
                padding: 0;
                max-width: 100%;
                display: block;
                margin-bottom: 0;
                &:nth-child(5n+5) {
                  clear: none;
                  margin-left: 0;
                }
                a {
                  &:before {
                    display: inline-block;
                    font-family: @font-family-icon;
                    margin-right: 5px;
                    content: "\f101";
                  }
                }
              }
            }
          }
          &.container {
            border-top: none;
          }
        }
      }
    }
  }
}

.wrapp-sous-rub .level-2 > a {
  font-weight: 700;
  hyphens: auto;
  word-break: normal;
}
.wrapp-sous-rub .level-2 > a > span {
  margin-left: -1.5em;
  transition: all ease .5s;
}
.wrapp-sous-rub .level-2.hover > a > span {
  transform: rotate(90deg);
}

#wrapper-water {
  background: @water-section;
  .container {
    border-top: 1px solid #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 30px;
    padding-bottom: 45px;
    @media (min-width: @screen-md-min) {
      display: flex;

      p {
        margin: 0;
      }

      form {
        margin-top: 100px;
      }
    }
    // Ajout Makina pour select2 dans water-wapper
    .select2-selection {
      background-color: transparent;
      border: solid 2px #fff;
      border-radius: 22px;
      height: 44px;
    }

    .select2-container--open {
      .select2-selection {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        b {
          border-color: transparent transparent #fff;
        }
      }
    }

    .select2-selection__rendered {
      color: #fff;
      line-height: 40px;
      padding: 0 20px;
    }

    .select2-selection__placeholder {
      color: #fff;
    }

    .select2-selection__arrow {
      height: 42px;
      right: 20px
    }
  }
}

#wrapper-customer {
  background: @customer-section;
  .container {
    border-top: 1px solid #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 30px;
    padding-bottom: 45px;
    .visu {
      background-color: @help-section;
      position: relative;
      img {
        width: 100%;
        height: auto;
        mix-blend-mode: luminosity;
      }
      &:after {
        content: "";
        display: block;
        width: 45px;
        height: 45px;
        background-image: url('@{saur_default_path}/img/svg/assistance.svg');
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -22px 0 0 -22px;
      }
    }
  }
}

#page {
  display: block;
  position: relative;
}

/* Make slides display properly without the surrouding slideshow */
.slide-single {
  .item {
    position: relative;
    bottom: none;
    left: none;
  }
  > .media,
  > .media > img,
  > img {
    width: auto;
    height: 400px;
    float: right;
    margin-right: -100px;
    @media (min-width: @screen-sm-min) {
      width: 100%;
      height: auto;
      margin-right: 0;
    }
  }
  .item > div {
    position: absolute;
    bottom: 60px;
    left: 5px;
    z-index: 2;
    @media (min-width: @screen-sm-min) {
      bottom: 50px;
    }
    @media (min-width: @screen-md-min) {
      bottom: 60px;
    }
    @media (min-width: @screen-lg-min) {
      bottom: 100px;
    }
    .h1 {
      font-weight: 800;
    }
    .h3 {
      font-weight: 400;
      margin-top: 0;
    }
  }
}

/*SLIDESHOW TOP HP*/
.wrapper-slide-top {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  .container {
    position: relative;
    @media (max-width: @screen-xs-max) {
      padding: 0;
    }
    &:after {
      display: block;
      content: "";
      width: 350px;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      right: 100px;
      bottom: 30px;
      /* #14190: ugly because visible during download of images...
      -webkit-box-shadow: 0px 0px 100px 0px rgba(50, 50, 50, 0.8);
      -moz-box-shadow: 0px 0px 100px 0px rgba(50, 50, 50, 0.8);
      box-shadow: 0px 0px 100px 0px rgba(50, 50, 50, 0.8);
      */
    }
  }
  @media (min-width: @screen-sm-min) {
    margin-bottom: 60px;
  }
  .slide-top {
    z-index: 1;
    margin-bottom: 0;
    .item {
      > .media,
      > .media > img,
      > img {
        width: auto;
        height: 400px;
        float: right;
        margin-right: -100px;
        @media (min-width: @screen-sm-min) {
          width: 100%;
          height: auto;
          margin-right: 0;
        }
      }
      > div {
        position: absolute;
        bottom: 60px;
        left: 5px;
        z-index: 2;
        @media (min-width: @screen-sm-min) {
          bottom: 50px;
        }
        @media (min-width: @screen-md-min) {
          bottom: 60px;
        }
        @media (min-width: @screen-lg-min) {
          bottom: 100px;
        }
        .h1 {
          font-weight: 800;
        }
        .h3 {
          font-weight: 400;
          margin-top: 0;
        }
        .cta {
          margin-top: 15px;
          @media (min-width: @screen-md-min) {
            margin-top: 30px;
          }
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -66px;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-image: linear-gradient(130deg, white 30%, rgba(255, 255, 255, 0.5) 90%, rgba(255, 255, 255, 0) 110%);
        @media (min-width: @screen-sm-min) {
          background-image: linear-gradient(130deg, white 20%, rgba(255, 255, 255, 0) 75%);
        }
      }
    }
  }
  .slide-top-control {
    position: absolute;
    z-index: 2;
    bottom: 0;
    background-color: #fff;
    font-size: @font-size-x-small;
    height: 48px;
    .slick-list,
    .slick-track {
      height: 100%;
    }
    .slick-track {
      width: auto !important;
    }
    .item {
      cursor: pointer;
      font-size: @font-size-x-small;
      color: @gray-light;
      font-weight: 800;
      line-height: 48px;
      padding: 0 22px;
      position: relative;
      p {
        display: none;
        vertical-align: middle;
        margin: 0;
        @media (min-width: @screen-md-min) {
          display: inline-block;
        }
      }
      &:before {
        display: inline-block;
        content: "";
        height: 8px;
        width: 8px;
        border: 1px solid @gray-light;
        vertical-align: middle;
        border-radius: 50%;
        @media (min-width: @screen-md-min) {
          margin-right: 10px;
        }
      }
      &:after {
        display: block;
        content: "";
        height: 22px;
        border-left: 1px solid @gray-lighter;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:hover {
        border-bottom: 3px solid @brand-primary;
      }
      &.slick-current {
        p {
          display: none;
        }
        &:before {
          height: 12px;
          width: 12px;
          border: 4px solid @brand-primary;
          margin-right: 0;
        }
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -66px;
    right: 50%;
    bottom: 0;
    background: #fff;
  }
  figcaption {
    display: none;
  }
}

/*LAYOUT*/

/*>>>> Accueil / Tuilles */
.ligne {
  margin-bottom: 0;
  position: relative;
  @media (min-width: @screen-sm-min) {
    margin-bottom: 30px;
  }
  @media (min-width: @screen-md-min) {
    margin-bottom: 60px;
    display: flex;
  }
}

.tile {
  font-size: @font-size-small;
  margin-bottom: 15px;
  display: inline-block;
  @media (min-width: @screen-sm-min) {
    height: 250px;
  }
  @media (min-width: @screen-md-min) {
    margin-bottom: 0;
    min-height: 350px;
    /*height:auto;*/
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    right: 15px;
    overflow: hidden;
    //backface-visibility: hidden;
    padding: 0;
    .media {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    img {
      display: block;
      height: 100%;
      width: auto;
      max-width: none;
      opacity: 0.2;
      mix-blend-mode: luminosity;
    }
  }
  > div, > a {
    height: 100%;
    padding-bottom: 90px;
    div.tile-bottom {
      position: absolute;
      bottom: 20px;
      left: 15px;
      right: 15px;
      transition: all ease-in-out 0.5s;
      p {
        display: block;
        transition: all ease-in-out 0.3s;
        width: 78%;
        position: relative;
        margin: 5px 0 0 0;
      }
    }
  }
  h2 {
    font-weight: 800;
    display: inline-block;
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 0;
    z-index: 2;
    &:after {
      display: block;
      width: 100%;
      height: 2px;
      content: "";
      background: @gray-light;
      position: absolute;
      bottom: -1px;
      transition: all ease-in-out 0.3s;
    }
  }
  .stroke {
    position: relative;
    left: auto;
    right: auto;
    border-top: 1px solid @gray-lighter;
    bottom: auto;
    clear: both;
    z-index: 1;
    transition: all ease-in-out 0.3s;
  }
  p {
    margin: 15px 0;
  }
}

.tile-xs {
  .overlay {
    background-color: @brand-primary;
    background-image: linear-gradient(45deg, @brand-primary 25%, @degrad-tile-end 90%);
  }
  > a {
    position: relative;
    display: inline-block;
    background-color: #fff;
    color: @gray-light;
    transition: all ease-in-out 0.3s;
    @media (min-width: @screen-md-min) {
      &:focus,
      &:hover {
        background-color: transparent;
        text-decoration: none;
        box-shadow: 0px 0px 20px 5px #cfcfcf;
        color: #fff !important;
        h2 {
          &:after {
            opacity: 0;
          }
        }
        .stroke {
          bottom: auto;
          opacity: 0;
        }
        div.tile-bottom {
          bottom: 30px;
          p {
            margin-top: 15px;
            padding-top: 15px;
          }
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
  div.tile-bottom {
    p {
      float: left;
      width: 60%;
      @media (min-width: @screen-md-min) {
        width: auto;
        float: none;
      }
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: -15px;
        right: -15px;
        top: 0;
        border-top: 1px solid #fff;
        transition: all ease-in-out 0.3s;
      }
    }
    svg {
      float: right;
      width: 50px;
      height: 60px;
      @media (min-width: @screen-md-min) {
        float: none;
      }
      fill: @gray-lighter;
    }
  }
}

.tile.simul {
  a {
    background-color: @brand-primary;
    color: #fff;
    h2 {
      &:after {
        background: #fff;
      }
    }
    .stroke {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
    }
    div {
      p {
        &:before {
          border-top: 1px solid @brand-primary;
        }
      }
      svg {
        fill: #fff;
      }
    }
    @media (min-width: @screen-md-min) {
      &:focus,
      &:hover {
        background-color: transparent;
        div {
          p {
            &:before {
              border-top: 1px solid #fff !important;
            }
          }
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

.tile.water {
  color: #fff;
  a {
    color: #fff;
    background: @water-section;
    @media (min-width: @screen-md-min) {
      &:focus,
      &:hover {
        background-color: transparent;
        div {
          p {
            &:before {
              border-top: 1px solid #fff !important;
            }
          }
          svg {
            fill: #fff;
          }
        }
      }
    }
  }

  .overlay {
    background: @water-section;
    img {
      opacity: 0.35;
      mix-blend-mode: multiply;
      margin: auto;
      display: block;
      @media (min-width: @screen-md-min) {
        width: 140%;
        height: auto;
        margin-left: -25%;
        margin-top: 15%;
      }
      @media (min-width: @screen-lg-min) {
        margin-left: -20%;
        margin-top: 0;
      }
    }
  }
  h2 {
    &:after {
      background: #fff;
    }
  }
  .stroke {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
  div.tile-bottom {
    input {
      width: 70%;
    }
    .btn {
      overflow: hidden;
      text-align: right;
      padding: 10px 13px;
      span {
        display: none;
      }
      &:after {
        display: inline-block;
        font-family: @font-family-icon;
        content: "\f178";
      }
    }
    p {
      &:before {
        border-top: 1px solid @water-section;
      }
    }
    svg {
      fill: #fff;
    }
  }
}

.tile.customer {
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: @screen-sm-max) {
    height: 515px;
  }
  @media (max-width: @screen-xs-max) {
    height: auto;
  }
  @media (min-width: @screen-md-min) {
    min-height: 350px;
  }
  color: #fff;
  .overlay {
    background-color: @customer-section;
    img {
      height: auto;
      width: 100%;
      @media (min-width: @screen-sm-min) and (max-width: @screen-md) {
        height: 100%;
        width: auto;
      }
    }
  }
  h2 {
    &:after {
      background: #fff;
    }
  }
  .stroke {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
  ul {
    position: relative;
    margin: 20px 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      width: 100%;
      &:before {
        display: inline-block;
        float: left;
        font-family: @font-family-icon;
        margin-right: 12px;
        margin-bottom: 12px;
        content: "\f05d";

      }
      @media (min-width: @screen-sm-min) {
        margin-bottom: 10px;
        &:before {
          float: none;
          margin-bottom: 0;
        }
      }
    }
  }
  p {
    position: relative;
    a:not(.btn) {
      color: #fff;
      font-size: @font-size-x-small;
      padding-left: 24px;
      text-decoration: underline;
    }
  }
  div.tile-bottom {
    svg {
      float: right;
      @media (min-width: @screen-md-min) {
        float: none;
      }
      fill: #fff;
    }
  }
}

.dossiers {
  @media (min-width: @screen-sm-min) {
    display: flex;
  }
}

.tile.dossier {
  height: auto;
  color: #fff;
  a {
    color: #fff;
  }
  .overlay {
    background: @gray-light !important;
  }
  .title {
    font-size: @font-size-x-small;
    margin: 30px 0;
    a {
      text-decoration: underline;
    }
  }
  h2 {
    &:after {
      background: #fff;
    }
  }
  .stroke {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
  h3 {
    font-weight: 800;
  }
  div.visu {
    position: relative;
    height: 100%;
    text-align: right;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0;
    overflow: hidden;
    img, .media {
      width: 100%;
      height: auto;
    }
    @media (min-width: @screen-md-min) {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0;
      margin-left: 0;
      img, .media {
        height: 100%;
        width: auto;
        opacity: 0.5;
      }
    }
  }
  div.tile-bottom p::before {
    border-color: @gray-light;
  }
}

.tile.dossier + .tile-dossier {
  .overlay {
    background-color: @gray;
  }
}

.tile.help {
  color: #fff;
  @media (min-width: @screen-sm-min) {
    height: auto;
  }
  .overlay {
    background-color: @help-section;
    img {
      opacity: 0.3;
      @media (max-width: @screen-sm-max) {
        width: 100%;
        height: auto;
      }
      @media (max-width: @screen-xs-max) {
        display: none;
      }
    }
  }
  h2 {
    &:after {
      display: none;
    }
  }
  ul {
    position: relative;
    font-weight: 500;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 5px 10px 0;
    }
  }
  div.tile-bottom {
    svg {
      fill: #fff;
    }
  }
}

/*>>>> Inter */

.not-front #page {
  &:before {
    background-color: white;
    height: 530px;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 50%;
    top: 0;
    z-index: -1;
  }
  .content-inter {
    letter-spacing: 0.025em;
    position: relative;
    &:before {
      background-color: white;
      height: 424px;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 15px;
      top: 0;
      z-index: -1;
    }
  }
}

.breadcrumb {
  background-color: transparent;
  padding: 120px 35px 0 35px;
  .breadcrumb {
    padding: 0;
    margin: 0;
  }
  font-size: @font-size-xx-small;
  color: @gray-light;
  a {
    color: @gray-light;
  }
  > li + li:before {
    content: "@{breadcrumb-separator}";
  }
  @media (min-width: @screen-sm-min) {
    padding: 41px 35px 0 35px;
  }
}

.zone-titre {
  padding: 0 35px;
  margin-bottom: 30px;
  h1 {
    margin-top: 0;
    font-weight: 800;
  }
}

.visu-top {
  overflow: hidden;
}

@media (max-width: @screen-md-max) {
  .visu-top {
    overflow: hidden;
    height: 31vw;
    max-height: auto;
  }
}

.nav-left {
  //margin-top: -20px;
  //@media (min-width: @screen-sm-min) {
  //  margin-top: -50px;
  //}
  //@media (min-width: @screen-md-min) {
  //  margin-top: -70px;
  //  @media (min-width: @screen-lg-min) {
  //    margin-top: -90px;
  //  }
  //}
  //@media (min-width: @screen-lg-min) {
  //  margin-top: -90px;
  //}
  .content {
    background-color: @brand-primary;
    color: white;
    padding: 25px 15px;
    line-height: 1.1;
    @media (min-width: @screen-sm-min) {
      padding: 35px 25px;
    }
    .menu-title {
      hyphens: auto;
      //word-break: break-all;
      hyphens: auto;
      font-weight: 800;
      position: relative;
      padding-bottom: 26px;
      margin-bottom: 20px;
      @media (min-width: @screen-sm-min) {
        font-size: @font-size-large;
      }
      /*@media (min-width: @screen-md-min) {
        font-size: @font-size-x-large;
      }
      @media (min-width: @screen-lg-min) {
        font-size: @font-size-xx-large;
      }*/

      &:before {
        display: block;
        position: absolute;
        content: "";
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        bottom: 0;
        left: -15px;
        right: -15px;
        @media (min-width: @screen-sm-min) {
          left: -25px;
          right: -25px;
        }
      }
      &:after {
        display: block;
        position: absolute;
        content: "";
        border-top: 2px solid white;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        font-size: @font-size-small;
        line-height: 1.4;
        margin: 10px 0;
        @media (min-width: @screen-sm-min) {
          font-size: @font-size-x-small;
        }
        @media (min-width: @screen-md-min) {
          font-size: @font-size-small;
        }
      }
    }
    a {
      color: white;
    }
    ul.children {
      margin-left: @padding-large-horizontal;
      li {
        font-size: 86%; // ~12px
        font-weight: normal;
        a {
          &:before {
            display: inline-block;
            font-family: @font-family-icon;
            margin-right: 5px;
            content: "\f101";
          }
        }
      }
    }
  }
}

.visu-top + .ligne {
  @media (min-width: @screen-sm-min) {
    margin-top: -50px;
  }
  @media (min-width: @screen-md-min) {
    margin-top: -70px;
  }
  @media (min-width: @screen-lg-min) {
    margin-top: -90px;
  }
}

.visu-top img {
  width: 100%;
  max-width: inherit;
}

// CKeditor-friendly
body.cke_editable,
.inter .content {
  &:extend(.clearfix all);
  background-color: white;
  padding: 25px 15px;
  @media (min-width: @screen-sm-min) {
    padding: 35px;
  }
  h1, .h1, h2, .h2, h3, .h3 {
    font-weight: 800;
    line-height: 1.1;
  }
  h2:first-child {
    margin-top: 0;
  }
  p {
    margin: 0 0 25px 0;
  }
  blockquote {
    border-left: none;
    margin: 20px 0;
    padding: 0 0 0 15%;
    font-weight: 300;
    line-height: 1.1;
    footer {
      display: block;
      font-size: @font-size-xx-small;
      font-weight: 800;
      &:before {
        content: "";
      }
    }
  }
  figure {
    display: inline-block;
    width: 100%;
    //margin: 0 0 25px 0;
    margin-bottom: 25px;
    img {
      width: 100%;
      height: auto;
      @media (min-width: @screen-sm-min) {
        float: right;
      }
    }
    &.with-caption img {
      @media (min-width: @screen-sm-min) {
        width: 85%;
      }
    }
    figcaption {
      font-size: @font-size-xx-small;
      padding: 5px 0 10px 0;
      color: @gray-light;
      border-bottom: 1px solid @gray-light;
      @media (min-width: @screen-sm-min) {
        width: 15%;
        padding: 0 15px 10px 0;
      }
    }
  }
}

.inter {
  margin-bottom: 15px;
  @media (min-width: @screen-sm-min) {
    margin-bottom: 0;
    //margin-top: -50px;
  }
  //@media (min-width: @screen-md-min) {
  //  margin-top: -70px;
  //}
  //@media (min-width: @screen-lg-min) {
  //  margin-top: -90px;
  //}
  .share {
    background-color: @gray-light;
    padding: 25px 15px 10px 15px;
    color: white;
    font-size: @font-size-xx-small;
    @media (min-width: @screen-sm-min) {
      font-size: @font-size-x-small;
      padding: 25px 35px 10px 35px;
    }
    @media (min-width: @screen-md-min) {
      padding: 35px;
    }
    @media (min-width: @screen-lg-min) {
      font-size: @font-size-small;
    }
    a {
      color: white;
    }
    button {
      color: @gray-light;
      margin-right: 35px;
      font-size: @font-size-x-small;
      display: block;
      margin-bottom: 15px;
      @media (min-width: @screen-md-min) {
        display: inline-block;
        margin-bottom: 0;
      }
      @media (min-width: @screen-lg-min) {
        font-size: @font-size-small;
      }
      &:hover,
      &:focus {
        color: white;
      }
      &:after {
        display: inline-block;
        content: "\f1d9";
        font-family: @font-family-icon;
        margin-left: 30px;
      }
    }
    a.btn-utile {
      padding: 7px 10px;
      margin: 0 0 0 5px;
      @media (min-width: @screen-md-min) {
        padding: 10px 13px;
      }
      &:hover,
      &:focus {
        color: @gray-light;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      display: inline-block;
      width: 100%;
      @media (min-width: @screen-md-min) {
        float: right;
        width: auto;
      }
      li {
        list-style: none;
        float: left;
        margin: 10px 0 10px 10px;
      }
    }
  }
}

.faq {
  .nav-left .content,
  .visu-top {
    background-color: @help-section;
    img {
      opacity: 1;
    }
  }
  .inter {
    .content {
	  padding-left: 10%;
	  h2 {
	   margin-left: -5%;
	  }
	}
  }
}

//.contact,
.form {
  .error-message {
    color: @brand-danger;
    font-weight: bold;
  }
  .visu-top.visu-form-default {
    max-height: 300px;
    //background-color: @brand-primary;
    img {
      opacity: 0.3;
      //mix-blend-mode: multiply;
    }
  }
  .visu-top {
    background-color: @brand-primary;
    img {
      opacity: 1;
      mix-blend-mode: luminosity;
    }
  }
  button.btn-afficher-contacts {
    &:after {
      display: inline-block;
      font-family: @font-family-icon;
      content: "\f00c";
      margin-left: 30px;
    }
  }
  .resultats-infos-contact {
    margin-top: 0px;
    display: inline-block;
    width: 100%;
    @media (min-width: @screen-sm-min) {
      display: flex;
    }
    div {
      font-size: @font-size-x-small;
      text-transform: uppercase;
      border: 1px solid @gray-lighter;
      padding-top: 30px;
      padding-bottom: 30px;
      @media (min-width: @screen-sm-min) {
        border-right: none;
      }
      .titre {
        font-size: @font-size-base;
        text-transform: none;
        height: 70px;
        border: none;
        padding: 0;
        position: relative;
        span {
          text-transform: uppercase;
          font-weight: 800;
        }
        &:after {
          display: inline-block;
          font-family: @font-family-icon;
          content: "\f095";
          position: absolute;
          right: 5%;
          top: 0;
        }
      }
      strong {
        font-size: @font-size-base;
      }
      & + div {
        text-transform: none;
        font-size: @font-size-base;
        border: none;
        background-color: @brand-primary;
        color: white;
        .titre {
          &:after {
            content: "\f124";
          }
        }
        &.ass, &.anc {
          background-color: @ass-color;
        }
        &.eau {
          background-color: @eau-potable-color
        }
      }
    }
  }
}

@supports (mix-blend-mode: luminosity) {
  .form .visu-top.visu-form-default img {
    opacity: 1;
    mix-blend-mode: luminosity;
  }
}

/* FORMULAIRES */
.legal-info {
  margin-top: 1em;
}
.inter form {
  // https://stackoverflow.com/questions/7817269/css-container-div-not-getting-height
  fieldset, .first-panel, #connection-location {
    clear: both;
    overflow: hidden;
  }
  fieldset {
    border-top: 1px dashed @gray-lighter;
    border-right: 1px dashed @gray-lighter;
    border-bottom: 1px dashed @gray-lighter;
    display: inline-block;
    width: 100%;
    margin: 30px 0;
    padding: 15px 15px 15px 0;
    legend {
      text-transform: uppercase;
      font-weight: 800;
      color: @brand-primary;
      font-size: @font-size-medium;
      border: none;
      width: auto;
      margin: 0;
      padding-right: 20px;
    }
  }
  label {
    font-size: @font-size-small;
  }
  .form-control {
    display: block;
    border-radius: 0;
    box-shadow: none;
    color: @brand-primary;
    font-size: @font-size-small;
    height: 40px;
    padding: 0 12px;
    @media (min-width: @screen-md-min) {
      width: 50%;
    }
    &:focus {
      box-shadow: none;
      border: 1px solid @brand-primary;
    }
  }
  textarea.form-control {
    height: 150px;
  }
  .wrapper-select {
    position: relative;
    width: 100%;
    &:after {
      display: block;
      width: 40px;
      height: 40px;
      background: @brand-primary;
      color: white;
      font-family: @font-family-icon;
      content: "\f107";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      text-align: center;
      line-height: 40px;
    }
    @media (min-width: @screen-md-min) {
      width: 50%;
    }
    select {
      padding-right: 50px; // avoid ::after masking text (includes margin)
      width: 100%;
      position: relative;
      z-index: 2;
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:-ms-expand {
        display: none;
      }
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    height: auto;
  }
  .form-inline {
    label {
      margin-right: 15px;
    }
    @media (min-width: @screen-sm-min) {
      input[type="radio"],
      input[type="checkbox"] {
        margin: 4px 5px 0 0;
      }
    }
    &.input-full {
      padding: 0;
      .form-group {
        padding: 0;
        .form-control {
          width: 100%;
          @media (min-width: @screen-md-min) {
            width: 90%;
          }
        }
        &:last-child .form-control {
          width: 100%;
        }
      }
    }
    .wrapper-select {
      padding: 0;
      display: inline-block;
      &.col-xs-4 {
        width: 33.333333%;
      }
      @media (min-width: @screen-md-min) {
        &.col-md-2 {
          width: 16.6667%;
        }
      }
      &:last-child {
        select {
          width: 100%;
        }
        &:after {
          right: 0;
        }
      }
    }
  }
  .iban {
    margin-bottom: 9px;
    input {
      margin: 0 6px 6px 0;
      padding: 0 9px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .help-block,
  .or {
    color: @gray-light;
    font-size: @font-size-x-small;
    //margin-top: -10px;
  }
  .help-block {
    margin-bottom: 15px;
  }
  input + .help-block {
    margin-top: -10px;
  }
  .or {
    margin-bottom: 0;
  }
  .filechunk-widget-drop {
    padding: 12px 30px;
    width: 100%;
    border: 2px solid @brand-primary;
    border-radius: 30px;
    display: block;
    transition: all ease-in-out 0.3s;
    @media (min-width: @screen-sm-min) {
      width: 106%;
    }
    @media (min-width: @screen-md-min) {
      width: 52%;
    }
    &:after {
      font-family: @font-family-icon;
      content: "\f0ed";
      display: inline-block;
      padding-left: .5em;
      //float: right;
    }
    .text-muted {
      color: @brand-primary;
      transition: all ease-in-out 0.3s;
      display: inline-block;
      width: 90%;
    }
    &:focus,
    &:hover {
      background: @brand-primary;
      color: white;
      .text-muted {
        color: white;
      }
    }
  }
  .check {
    width: 96%;
    text-align: left;
    @media (min-width: @screen-md-min) {
      width: 49%;
    }
    &:before {
      font-family: @font-family-icon;
      content: "\f00c";
      display: inline-block;
      float: right;
    }
  }
  .required:after {
    content: '*';
    color: @help-section;
    font-size: 4rem;
    line-height: 0;
    //font-variant: sub;
    vertical-align: middle;
  }
  .radio label:after {
    content: '';
  }
  .required + div > .required:after {
    content: '';
  }
  &:after {
    content: '* champs obligatoires';
    color: @help-section;
    font-size: @font-size-small;
    &::first-letter {
      font-size: 4rem;
      line-height: 0;
      font-variant: sub;
    }
  }
  .glyphicon-question-sign.show {
    display: inline-block !important;
    padding: 0 .5rem;
  }
}

/*MOTEUR DE RECHERCHE FOOTER*/
.search-bottom {
  form {
    background: @brand-primary-dark;
    height: 60px;
    padding-right: 0;
    input, button {
      border: none;
      background: transparent;
      height: 60px;
      color: #fff;
    }
    input {
      width: 80%;
      font-size: @font-size-small;
      @media (min-width: @screen-sm-min) {
        width: 90%;
      }
      &::-webkit-input-placeholder {
        color: @gray-light;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: @gray-light;
        opacity: 1;
      }
      /* firefox 19+ */
      &:-ms-input-placeholder {
        color: @gray-light;
        opacity: 1;
      }
      /* ie */
      &:-moz-placeholder {
        color: @gray-light;
        opacity: 1;
      }
    }
    button {
      position: absolute;
      z-index: 1;
      top: 0;
      right: -22px;
      overflow: hidden;
      text-indent: -500px;
      border-left: 1px solid #fff;
      width: 50px;
      @media (min-width: @screen-sm-min) {
        width: 100px;
      }
      &:after {
        display: inline-block;
        font-family: @font-family-icon;
        content: "\f002";
        font-size: @font-size-medium;
        position: absolute;
        right: 0;
        text-align: right;
        padding: 0 17px;
        @media (min-width: @screen-sm-min) {
          padding: 0 42px;
        }
      }
    }
    &:after {
      display: block;
      background: @brand-primary-dark;
      content: "";
      width: 22px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -22px;
    }
  }
  .btn-top {
    background: #fff;
    width: 45px;
    height: 60px;
    text-align: center;
    &:after {
      display: inline-block;
      font-family: @font-family-icon;
      content: "\f176";
      line-height: 60px;
      color: @gray-light;
    }
    span {
      display: none;
    }
  }
}

/*FOOTER*/
.footer {
  background: #fff;
  font-size: @font-size-x-small;
  @media (min-width: @screen-sm-min) {
    font-size: @font-size-small;
  }
  .container {
    padding-top: 30px;
    @media (min-width: @screen-sm-min) {
      padding-top: 70px;
    }
    .baseline {
      margin-top: 20px;
      margin-bottom: 25px;
      font-size: @font-size-base;
      color: @customer-section;
      @media (min-width: @screen-sm-min) {
        font-size: @font-size-medium;
      }
      span {
        color: @water-section;
        display: block;
      }
    }
    .navig-bottom {
      padding-right: 0;
      .line {
        display: inline-block;
        width: 100%;
        color: @gray-light;
        margin-left: -15px;
        margin-right: -15px;
        @media (min-width: @screen-sm-min) {
          margin-bottom: 20px;
          margin-left: 0;
          margin-right: 0;
        }
        ul {
          padding: 0;
          li {
            list-style: none;
            &.title {
              font-weight: 800;
              a {
                display: block;
                margin-bottom: 30px;
                @media (min-width: @screen-sm-min) {
                  margin-bottom: 15px;
                }
              }
              ul {
                li {
                  font-weight: 400;
                  a {
                    margin-bottom: 0;
                    &:before {
                      display: inline-block;
                      content: "-";
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
            a {
              color: @gray-light;
            }
          }
        }
      }
    }
    .water,
    .customer {
      color: #fff;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;
      @media (min-width: @screen-md-min) {
        text-align: right;
      }
      .title {
        font-weight: 800;
        color: #fff;
        &:before {
          display: inline-block;
          font-family: @font-family-icon;
          margin-right: 10px;
        }
      }
    }
    .water {
      background: @water-section;
      .title {
        &:before {
          content: "\f041";
        }
      }
    }
    .customer {
      background: @customer-section;
      .title {
        &:before {
          content: "\f007";
        }
      }
      ul {
        padding: 0;
        li {
          list-style: none;
          margin-bottom: 5px;
          a:not(.btn) {
            color: #fff;
          }
        }
      }
    }
    .sub-footer {
      margin: 30px 0 15px 0;
      padding-top: 15px;
      font-size: @font-size-x-small;
      border-top: 1px solid @gray-lighter;
      margin-left: -15px;
      margin-right: -15px;
      @media (min-width: @screen-sm-min) {
        margin-left: 0;
        margin-right: 0;
      }
      ul, a {
        color: @gray-light;
        margin: 0;
        padding: 0;
      }
      li {
        list-style: none;
        display: inline-block;
        &:after {
          display: inline-block;
          content: "-";
          margin: 0 5px;
        }
        &:last-child:after {
          display: none;
        }
      }
    }
  }
}

/*BLOC BESOIN D'AIDE*/
#need-help {
  position: fixed;
  top: 50%;
  right: 3%;
  margin-top: -70px;
  z-index: 90;
  text-align: right;
  a.open {
    display: inline-block;
    text-align: center;
    width: 70px;
    height: 70px;
    background-color: @help-section;
    color: #fff;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    margin-right: 20px;
    box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.25);
    transition: all ease-in-out 0.3s;
    svg {
      fill: #fff;
      margin-top: 15px;
      width: 32px;
      display: inline-block;
    }
    &.opened {
      color: @help-section;
      background-color: #fff !important;
      svg {
        fill: @help-section;
        color: #fff;
      }
    }
  }
  a.btn-close {
    display: none;
    //background-color: #fff;
    background-color: @help-section;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    width: 30px;
    top: 30px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    top: 27px;
    left: 10px;
    border-radius: 50%;
    z-index: 2;
    &:before {
      display: inline-block;
      font-family: @font-family-icon;
      content: "\f00d";
      font-size: @font-size-medium;
    }
  }
  div {
    color: @help-section;
    font-size: @font-size-small;
    padding: 40px 20px 20px 20px;
    position: relative;
    margin-top: -30px;
    z-index: 1;
    height: 70px;
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    p {
      font-weight: 800;
      text-shadow: 0px 1px 8px rgba(0, 0, 0, .5);
    }
    a {
      color: @help-section;
    }
    ul {
      padding: 0;
      margin: 15px 0;
      width: 100%;
      text-align: left;
      li {
        list-style: none;
      }
    }
    &.opened {
      background: #fff;
      box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);
      height: 200px;
      text-align: left;
    }
  }
  @media (max-width: @screen-sm-max) {
    display: none;
  }
  @media (min-width: @screen-lg-min) {
    margin-top: -70px;
    a.open {
      width: 100px;
      height: 100px;
      margin-right: 25px;
      svg {
        margin-top: 22px;
        width: 45px;
        height: 51px;
      }
    }

    a.btn-close {
      width: 40px;
      //top: 40px;
      line-height: 40px;
      top: 35px;
    }

    div {
      padding: 65px 25px 25px 25px;
      margin-top: -45px;
      height: 80px;
      &.opened {
        height: 230px;
      }
    }
  }
}

//////

/*BLOC BESOIN D'AIDE*/
#cookies {
  position: fixed;
  bottom: 0;
  right: 3%;
  z-index: 200;
  a.btn-close {
    background-color: #fff;
    background-color: @brand-primary;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    width: 30px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    top: -15px;
    right: 10px;
    border-radius: 50%;
    z-index: 2;
    &:before {
      display: inline-block;
      font-family: @font-family-icon;
      content: "\f00d";
      font-size: @font-size-medium;
    }
  }
  > div {
    font-size: @font-size-small;
    padding: 20px 30px;
    position: relative;
    z-index: 1;
    width: 320px;
    color: @gray;
    background: #fff;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);
    transition: all ease-in-out 0.3s;
    a {
      color: @brand-primary;
    }
    &:after {
      display: block;
      content: "";
      background: @brand-primary;
      position: absolute;
      left: 30px;
      bottom: 0;
      right: 30px;
      height: 5px;
    }
  }
}

.m-b-30 {
  margin-bottom: 30px;
}

.liste-01 {
  padding: 0;
  li {
    list-style: none;
    &:before {
      display: inline-block;
      content: "-";
      margin-right: 10px;
    }
  }
}

.navbar-inverse.navbar-saur {
  &.bg-primary {
    background-color: @brand-primary;
    li:not(.customer) {
      background-color: @brand-primary;
    }
    &:before {
      background-color: @customer-section;
    }
  }
  &.bg-primary-dark {
    background-color: @brand-primary-dark;
    &:before {
      background-color: transparent;
    }
  }
  &.bg-water {
    background-color: @water-section;
    li:not(.customer) {
      background-color: @water-section;
    }
    &:before {
      background-color: lighten(@customer-section, 10%);
    }
  }
  &.bg-customer {
    background-color: @customer-section;
    &:before {
      background-color: transparent;
    }
  }
}

.navbar-inverse.navbar-saur .container .main-navig > li.not-current a,
.navbar-inverse.navbar-saur .container .main-navig > li.not-current a:hover {
  background-color: transparent;
  opacity: 0.5;
  border-left: 1px solid #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 0; // Supprime 1px de padding pour compenser le border-left
  @media (min-width: @screen-sm-min) {
    padding-left: 9px; // Supprime 1px de padding pour compenser le border-left
  }
  @media (min-width: @screen-md-min) {
    padding-left: 19px; // Supprime 1px de padding pour compenser le border-left
  }
  @media (min-width: @screen-lg-min) {
    padding-left: 31px; // Supprime 1px de padding pour compenser le border-left
  }
}

#menu-siblings .active {
  font-weight: 700;
}

.form-inline .wrapper-select,
.birthdate-group .wrapper-select {
  width: auto !important;
  @media (max-width: @screen-sm-min) {
    width: 32% !important;
  }
}

.page-error {
  .zone-titre {
    margin-top: 140px;
  }
  @media (min-width: @screen-sm-min) {
    .zone-titre {
      margin-top: 52px;
    }
  }
}

.popover-content {
  font-size: @font-size-small;
}

.legal-info {
  color: @gray-light;
  font-size: .75em;
}

.document {
  img {
    max-width: 16px;
  }
}

@media (min-width: @screen-md-min) {
  .content.full-width {
    padding-right: 10% !important;
    padding-left: 10% !important;
  }
}

article,
ul.list-menu-children {
  .media-left {
    &:empty {
      min-width: 90px;
      height: 50px;
    }
    img {
      width: 80px !important;
      height: auto;
      max-width: unset;
    }
  }
  .inter .content & figure {
    margin: 0
  }
  ul {
    padding-left: 5em;
    margin-bottom: 2em;
  }
  li {
    margin: 1em 0;
  }
  .blended-faq, .blended-form {
    img, figure {
      mix-blend-mode: luminosity;
    }
  }
  .blended-faq {
    background-color: @help-section;
  }
  .blended-form {
    background-color: @brand-primary
  }
}

.alert {
  // Icons
  span.h1 {
    margin: 0 .2em .2em 0;
  }
  .h4 {
    margin-top: 0;
    margin-bottom: .5em;
  }
}

.privacy-settings {
  label {
    margin: 5px 5px 5px 25px;
  }
  input {
    height: inherit;
    margin-left: -22px !important;
  }
}

#relatedSiteTip {
    margin-top: 80px;
 }
