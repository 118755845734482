// roll over 10% dark

.btn-nav (@background) {
  a {
    background-color:@background;
    &:hover,
    &:focus {
      background-color: darken(@background, 10%);
    }
  }
}

// OVERRIDE BTN
.button-variant(@color; @colorover; @background; @backgroundover; @border) {
  color: @color;
  background-color: @background;
  border-color: @border;

  &:focus,
  &.focus {
    color: @colorover;
    background-color: @backgroundover;
    border-color: @border;
  }
  &:hover {
    color: @colorover;
    background-color: @backgroundover;
    border-color: @border;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @colorover;
    background-color: @backgroundover;
    border-color: @border;

    &:hover,
    &:focus,
    &.focus {
      color: @colorover;
      background-color: @backgroundover;
      border-color: @border;
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: @background;
      border-color: @border;
    }
  }
}

.button-filled-variant(@background; @color; @colorover; @border) {
  color:@color;
  background-color:@background;
  border-color:@border;

  &:focus,
  &.focus {
    color: @colorover;
    background-color: transparent;
  }
  &:hover {
    color: @colorover;
    background-color: transparent;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @colorover;
    background-color: transparent;

    &:hover,
    &:focus,
    &.focus {
      color: @colorover;
      background-color: transparent;
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: @background;
    }
  }
}