img {
  max-width: 100%;
}

// Autocomplete
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  ul {
    list-style: none none;
    margin: 0;
    padding: 0;
  }
  li {
    background: #fff;
    color: #000;
    cursor: default;
    white-space: pre;
  }
}
.element-invisible {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
