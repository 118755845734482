// Login form
.not-logged-in.page-user #page {
  max-width: 480px;
  margin: 3rem auto;
}

#contextual-pane {
  font-size: .875em
}

#tab-tree .tree-item {
  background-color: rgba(255, 255, 255, .5);
  padding: 5px 10px;
  border-radius: 2px
}

#tab-tree .tree-item:hover {
  background-color: rgba(255, 255, 255, .95);
  text-decoration: none
}

#contextual-pane-toggle {
  font-size: 24px
}

#contextual-pane.pane-right #contextual-pane-toggle {
  border-radius: 2px 0 0 2px
}

#contextual-pane.pane-right #contextual-pane-toggle a {
  padding: 8px 11px 4px
}

#contextual-pane.pane-right #contextual-pane-toggle a:first-child {
  border-radius: 2px 0 0
}

#contextual-pane.pane-right #contextual-pane-toggle a:last-child {
  border-radius: 0 0 0 2px
}

#contextual-pane.pane-down #contextual-pane-toggle {
  border-radius: 2px 2px 0 0
}

#contextual-pane.pane-down #contextual-pane-toggle a {
  padding: 6px 16px 2px
}

#contextual-pane.pane-down #contextual-pane-toggle a:first-child {
  border-radius: 2px 0 0
}

#contextual-pane.pane-down #contextual-pane-toggle a:last-child {
  border-radius: 0 2px 0 0
}

.table {
  font-size: .875em
}

#ucms-contrib-facets li {
  font-size: .75em;
  line-height: 1.4;
  .badge {
    font-size: 9px;
    vertical-align: top;
    line-height: 1.3;
    font-weight: 200;
  }
}

#contextual-pane.pane-right .inner {
  padding-top: 68px;
}

#contextual-pane > .inner > .actions {
  padding: 7px 15px;
}

.btn {
  font-size: 14px;
}

.btn-default {
  background-color: rgba(255, 255, 255, .9);
  transition: background-color 200ms;
  &:hover, &:active, &:focus {
    background-color: rgba(255, 255, 255, 1);
    color: #2c62a2;
  }
}

.ucms-cart-item h2 a, .ucms-contrib-result-inner h2 a {
  color: #fff;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 9px;
  text-align: center;
}

// Styles for ckeditor media embed
.document {
  font-weight: 700;
  border: 1px solid @brand-primary;
  border-radius: 2px;
  padding: @padding-base-vertical @padding-base-vertical;
}

.iframe-sizer {
  margin-bottom: @padding-large-vertical;
}

.content .body-media {
  margin-bottom: @padding-large-vertical;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.content .pull-left {
  margin-right: @padding-large-horizontal;
}

.content .pull-right {
  margin-left: @padding-large-horizontal;
}