// Restore admin related overrides
.navbar-inverse {
  font-size: 14px;
  z-index: @zindex-admin-navbar;
  // /!\ this property triggers a GPU rendering bug in Chromium
  // @see https://redmine.makina-corpus.net/issues/12613#note-5
}
#contextual-pane {
  z-index: @zindex-admin-contextual-pane;
}

//Tips
.tips, .infos, .infos-detail {
  background: mix(@brand-primary, #ffffff, 10%);
//  background-image: url("@{saur_default_path}/img/svg/tips.svg");
  background-image: url("data:image/svg+xml;base64,\
PHN2ZyB2ZXJzaW9uPScxLjEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycKCSB3aWR0\
aD0nNTBweCcgaGVpZ2h0PSc1Ni41MDVweCcgdmlld0JveD0nMCAwIDIyMCAyMzYnIHN0eWxlPSdlbmFi\
bGUtYmFja2dyb3VuZDogMCAwIDIyMCAyMzYnIHhtbDpzcGFjZT0ncHJlc2VydmUnPgo8cGF0aCBmaWxs\
PScjMkM2MkEyJyBkPSdNMS40LDEwMS4zYzIuMi0yNS43LDEyLjEtNDguMSwyOS45LTY2LjhDNDguMiwx\
Ni42LDY5LDUuNyw5My4zLDEuNmMzLjMtMC42LDYuNi0wLjksMTAtMS4yCgljMC4yLDAsMC41LDAuMSww\
LjYtMC4yYzQuNywwLDkuNCwwLDE0LjIsMGMwLjksMC41LDIsMC4zLDMsMC40YzIxLjEsMiw0MC4xLDku\
Myw1Ni44LDIyLjJjMTkuNywxNS4yLDMyLjksMzQuOCwzOS41LDU4LjgKCWMxLjgsNi43LDIuOSwxMy42\
LDMuNCwyMC41YzAsMC4zLTAuMSwwLjYsMC4yLDAuN2MwLDQuOSwwLDkuOCwwLDE0LjZjLTAuNiwxLjYt\
MC41LDMuMy0wLjcsNWMtMi4yLDE5LjEtOC45LDM2LjYtMjAuMyw1Mi4xCgljLTUsNi44LTEwLjUsMTIu\
OC0xNi41LDE4LjJjMC4zLDAuOCwwLjYsMS42LDAuOSwyLjRjMS4zLDMuMSwyLjQsNS42LDMuNCw3LjVj\
MSwyLDIuNCw0LjEsNC4xLDYuNWMxLjcsMi40LDMsNC4xLDMuOSw1CgljMC45LDEsMi4zLDIuNiw0LjMs\
NC45YzIsMi4zLDMuMywzLjcsMy45LDQuNGMwLjIsMC4yLDAuNSwwLjYsMSwxLjFjMC41LDAuNiwwLjgs\
MSwwLjksMS4xYzAuMSwwLjEsMC4zLDAuNSwwLjgsMS4xCgljMC40LDAuNiwwLjYsMSwwLjYsMS4yYzAs\
MC4yLDAsMC42LDAuMywxLjNjMC4yLDAuNywwLjIsMS4yLTAuMSwxLjV2MC4xYy0wLjMsMS41LTEuMSwy\
LjgtMi4yLDMuN2MtMS4xLDAuOS0yLjQsMS4zLTMuOCwxLjEKCWMtNS41LTAuNy0xMC4yLTEuNi0xNC40\
LTIuOGMtMTUuMy00LTI5LjMtMTAuMS00MS45LTE4LjVjLTQuMiwxLjMtOC41LDIuNS0xMi45LDMuM2Mt\
MjQuMSw0LjgtNDcuNSwyLTY5LjUtOQoJQzMyLDE5My42LDEyLjQsMTY4LjksMy45LDEzNS41Yy0xLjUt\
Ni4xLTIuMy0xMi4yLTIuNy0xOC41YzAtMC4zLDAuMi0wLjctMC4yLTAuOGMwLTMuOSwwLTcuOCwwLTEx\
LjgKCUMxLjUsMTAzLjQsMS4zLDEwMi4zLDEuNCwxMDEuM3ogTTg0LjcsMTA2LjdjMiwyLjIsMy43LDQu\
MSw1LDUuOGMxLjMsMS43LDIuNiwzLjgsNCw2LjRzMi4xLDQuOSwyLjMsNy4yCgljLTIuMSwxLjItMy4x\
LDMuMS0zLjEsNS41YzAsMS42LDAuNiwzLjEsMS43LDQuM2MtMS4xLDEuMi0xLjcsMi42LTEuNyw0LjNj\
MCwyLjMsMSw0LjEsMyw1LjRjLTAuNiwxLTAuOSwyLjEtMC45LDMuMQoJYzAsMiwwLjcsMy42LDIuMSw0\
LjdzMy4xLDEuNyw1LjIsMS43YzAuOSwyLDIuMiwzLjUsNCw0LjdjMS44LDEuMiwzLjcsMS43LDUuOCwx\
LjdjMi4xLDAsNC0wLjYsNS44LTEuN2MxLjgtMS4yLDMuMS0yLjcsNC00LjcKCWMyLDAsMy44LTAuNiw1\
LjItMS43czIuMS0yLjcsMi4xLTQuN2MwLTEuMS0wLjMtMi4xLTAuOS0zLjFjMi0xLjMsMy0zLjEsMy01\
LjRjMC0xLjYtMC42LTMuMS0xLjctNC4zYzEuMS0xLjIsMS43LTIuNiwxLjctNC4zCgljMC0yLjQtMS00\
LjItMy4xLTUuNWMwLjItMi4yLDAuOS00LjYsMi4zLTcuMnMyLjctNC43LDQtNi40YzEuMy0xLjcsMy0z\
LjYsNS01LjhjNC42LTUsNi45LTExLDYuOS0xNy44YzAtNC40LTEtOC41LTMtMTIuMwoJcy00LjYtNi45\
LTcuOC05LjVjLTMuMi0yLjUtNi45LTQuNS0xMC45LTUuOWMtNC4xLTEuNC04LjItMi4yLTEyLjQtMi4y\
Yy00LjIsMC04LjQsMC43LTEyLjQsMi4yYy00LjEsMS40LTcuNywzLjQtMTAuOSw1LjkKCWMtMy4yLDIu\
NS01LjgsNS43LTcuOCw5LjVzLTMsNy45LTMsMTIuM0M3Ny44LDk1LjcsODAuMSwxMDEuNiw4NC43LDEw\
Ni43eicvPgo8cGF0aCBmaWxsPScjMkM2MkEyJyBkPSdNOTQuNiw3My4xYzIuNS0xLjgsNS4yLTMuMSw4\
LjItNC4xczYtMS41LDkuMS0xLjVTMTE4LDY4LDEyMSw2OXM1LjcsMi40LDguMiw0LjFzNC41LDQsNiw2\
LjgKCWMxLjUsMi44LDIuMyw1LjcsMi4zLDguOWMwLDQuNS0xLjUsOC41LTQuNSwxMmMtMC40LDAuNS0x\
LjEsMS4yLTIsMi4yYy0wLjksMS0xLjYsMS43LTIsMi4yYy01LjcsNi44LTguOCwxMy40LTkuNCwxOS44\
aC0xNS4yCgljLTAuNi02LjQtMy43LTEzLTkuNC0xOS44Yy0wLjQtMC41LTEuMS0xLjItMi0yLjJzLTEu\
Ni0xLjctMi0yLjJjLTMtMy41LTQuNS03LjUtNC41LTEyYzAtMy4yLDAuOC02LjIsMi4zLTguOQoJQzkw\
LjIsNzcuMSw5Mi4yLDc0LjksOTQuNiw3My4xeiBNOTcuNiw5MC4zYzAuNCwwLjQsMC45LDAuNiwxLjUs\
MC42YzAuNiwwLDEuMS0wLjIsMS41LTAuNmMwLjQtMC40LDAuNi0wLjksMC42LTEuNQoJYzAtMiwxLjIt\
My42LDMuNi00LjdjMi40LTEuMSw0LjgtMS43LDcuMS0xLjdjMC42LDAsMS4xLTAuMiwxLjUtMC42YzAu\
NC0wLjQsMC42LTAuOSwwLjYtMS41YzAtMC42LTAuMi0xLjEtMC42LTEuNQoJYy0wLjQtMC40LTAuOS0w\
LjYtMS41LTAuNmMtMi4yLDAtNC40LDAuNC02LjYsMS4xYy0yLjIsMC43LTQuMSwxLjktNS44LDMuNmMt\
MS43LDEuNy0yLjUsMy43LTIuNSw2Qzk3LDg5LjQsOTcuMiw4OS45LDk3LjYsOTAuM3oKCScvPgo8L3N2\
Zz4=");
  background-repeat:no-repeat;
  background-position: 10px 10px;
  padding: 25px 70px 35px 70px;
  margin: 25px 0 25px 0;
  display: block;
  border: 1px dashed @brand-primary;
  font-size: 18px;
  text-align: justify;
}
.tips:before {
  font-size: 18px;
  margin: 0 0 10px 0;
  font-weight: 800;
  display: block;
}
.tips:before {
  content: "Astuce";
}
.tips.no-title {
  &:before {
    content: '';
  }
  h3 {
    margin-top: -10px;
  }
}